import { useEffect } from "react";
export default function App() {
  useEffect(() => {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href = "https://apps.apple.com/gb/app/clearwatt/id6447674962";
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.clearwatt.beta";
    } else {
      window.location.href = "https://clearwatt.co.uk";
    }
  }, []);
  return <></>;
}